// src/components/SettingsForm.js
import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import AdminLayout from "../../components/Admin/layout/AdminLayout";
import { useDispatch, useSelector } from 'react-redux';
import { updateWebsiteSettingsAsync } from '../../Slice/WebsiteSettings';
import { getWebsiteSettings } from '../../components/Admin/WebsiteApi';

const WebsiteSetting = () => {
  const dispatch = useDispatch();

  const websiteSettings = useSelector((state) => state.website);
  // console.log(websiteSettings)
  const [formData, setFormData] = useState({
    name: websiteSettings?.name,
    logo: '',
  });
  
  const [logoPreview, setLogoPreview] = useState('');

  const fetchWebsiteDetails = async () => {
    try{
      const data = await getWebsiteSettings();
      setFormData(data)
      setLogoPreview(data.logo)
    }catch(error){
      console.error('error fetching website details', error)
    };
  };

  useEffect(() => {
    fetchWebsiteDetails()
  }, [])

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'logo') {
      // Handle logo file input separately
      setFormData({ ...formData, [name]: files[0] }); // Use the selected file
      setLogoPreview(URL.createObjectURL(files[0])); // Create a preview URL for the selected file
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', formData.name);
    data.append('logo', formData.logo);

    dispatch(updateWebsiteSettingsAsync({ id: websiteSettings.id, data: data }))
    // Send a request to your server to update the settings with formData
    console.log(formData);
  };

  return (
    <AdminLayout>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Website Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <input
          type="file"
          accept="image/*"
          name="logo"
          onChange={handleInputChange}
        />
        <div>
          {logoPreview && (
            <img
              src={logoPreview}
              alt="Logo Preview"
              style={{ maxWidth: '100px', maxHeight: '100px' }}
            />
          )}
        </div>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </form>
    </AdminLayout>
  );
};

export default WebsiteSetting;
