import axios from 'axios';
import { toast } from 'react-toastify';

// const API_URL = 'http://localhost:5000/api/reviews';
// station-find-bke.onrender.com/
const API_URL = 'https://station-find-bke.onrender.com/api/reviews';
export const getReviews = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching reviews:', error);
    throw error;
  }
};

export const getstationReviews = async (station_id) => {
  try {
    const response = await axios.get(`${API_URL}/station/${station_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Stations:', error);
    throw error;
  }
};

export const addReview = async (newReview) => {
  try {
    const response = await axios.post(`${API_URL}/${newReview.station_id}`, newReview);
    return response.data;
  } catch (error) {
    console.error('Error adding review:', error);
    if(error.response.status === 500){
      window.open(`/500`, '_self');
    };
    throw error;
  }
};

export const deleteReview = async (stationId, reviewId) => {
  try {
    const response = await axios.delete(`${API_URL}/${stationId}/${reviewId}`);
    return response.data
  } catch (error) {
    console.error('Error deleting review:', error);
    throw error;
  }
};

export const updateReview = async (updatedReview) => {
  try {
    const response = await axios.patch(`${API_URL}/${updatedReview.station_id}/${updatedReview.reviewId}`, updatedReview);
    return response.data;
  } catch (error) {
    console.error('Error updating review:', error);
    if(error.response.status === 500){
      window.open(`/500`, '_self');
    };
    throw error;
  }
};

export const getSingleReview = async (station_id, reviewId) => {
  try {
    const response = await axios.get(`${API_URL}/single/${station_id}/${reviewId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting review:', error);
    throw error;
  }
};

export const getUserReview = async (username) => {
  try {
    const response = await axios.get(`${API_URL}/user/${username}`);
    return response.data;
  } catch (error) {
    console.error('Error getting user review:', error);
    throw error;
  }
};

export const likeReview = async (reviewId) => {
  try {
    const response = await axios.post(`${API_URL}/like/${reviewId}`);
    return response.data;
  } catch (error) {
    if(error.response.status === 401){
      toast.error('Please log in to perform this action', {
        position: toast.POSITION.TOP_CENTER
      });
      setTimeout(() => {
        window.open(`/login`, '_self');
      }, 2500);
    };
    throw error;
  }
};