import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LoadingIndicator = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh" // Adjust the height to match your layout
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingIndicator;
