import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "../Slice/UserSlice";
import AuthUserReducer from "../Slice/AuthUser";
import websiteReducer from "../Slice/WebsiteSettings"; // Import your website settings slice

const store = configureStore({
  reducer: {
    users: usersReducer,
    userAuth: AuthUserReducer,
    website: websiteReducer, // Include your website settings slice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, // Disable the ImmutableStateInvariantMiddleware
    }),
});

export default store;
