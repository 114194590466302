// components/ProtectedRoute.js

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoadingIndicator from '../components/resources/LoadingIndicator';

export const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state?.userAuth);
  
  // Get the current URL
  const currentURL = window.location.pathname;

  // Save the current URL to local storage
  useEffect(() => {
    localStorage.setItem('prevUrl', currentURL);
  }, [currentURL]);

  if (isAuthenticated.token) {
    if (isAuthenticated.user === null) {
      return <LoadingIndicator />; 
    } else if(isAuthenticated.user !== null) {
      return children; 
    }
  } else {
    // No token, meaning the user is not authenticated
    return <Navigate to="/login" />; // Redirect to the login page
  }
  
  // return isAuthenticated !== null ? children : <Navigate to="/login" />;
};

export const AdminRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state?.userAuth);
  
  if(isAuthenticated.token){
    if (isAuthenticated.user === null) {
      // User data is still loading; you can return a loading indicator or null
      return null;
    } else if (isAuthenticated.user?.role === 'admin') {
      return children;
    } else {
      return <Navigate to="/login" />;
    }
  }else{
    return <Navigate to="/login" />;
  }
  // return isAuthenticated === 'admin' ? children : <Navigate to="/login" />;
};
