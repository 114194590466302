import React, { useEffect, useRef } from 'react';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import L from 'leaflet';
import 'leaflet-routing-machine';

function Mapc() {
  const startLat = 7.2333;
  const startLng = 3.8667;
  const endLat = 7.48896;
  const endLng = 3.91945;

  // Use a useRef to hold the map instance
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) {
      // Initialize the map if it doesn't exist
      const map = L.map('map').setView([startLat, startLng], 13);
      mapRef.current = map;

      // Add a tile layer (you can use any map provider)
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      // Define start and end points
      const startPoint = L.latLng(startLat, startLng);
      const endPoint = L.latLng(endLat, endLng);

      // Create markers for start and end points
      const startMarker = L.marker(startPoint).addTo(map);
      const endMarker = L.marker(endPoint).addTo(map);

      // Create the routing control
      L.Routing.control({
        waypoints: [startPoint, endPoint],
        routeWhileDragging: true // Allows dragging the route
      }).addTo(map);
    }

    // Clean up the map when the component is unmounted
    return () => {
      if (mapRef.current) {
        // Remove all layers from the map before removing the map itself
        mapRef.current.eachLayer(layer => {
          mapRef.current.removeLayer(layer);
        });

        mapRef.current.remove();
        mapRef.current = null; // Reset the map reference
      }
    };
  }, [startLat, startLng, endLat, endLng]);

  return (
    <div id="map" className="map-container" style={{
      height: '400px',
      width: '100%', // Adjusted width to 100%
      border: '1px solid #ccc'
    }}></div>
  );
}

export default Mapc;
