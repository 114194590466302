import axios from "axios";

const API_URL = 'https://station-find-bke.onrender.com/api/website';
// const API_URL = 'http://localhost:5000/api/website';
export const getWebsiteSettings = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data.WebsiteSetting[0];
    } catch (error) {
        console.error('Error fetching Website:', error);
        // if(error.response.status === 500){
        //     window.open(`/500`, '_self');
        // };
        throw error;
    }
};


export const updateWebsiteSettings = async (id, data) => {
    try {
        const response = await axios.put(`${API_URL}/${id}`, data);
        return response.data;
    } catch (error) {
        console.error('Error Updating Station:', error);
        // if(error.response.status === 500){
        //     window.open(`/500`, '_self');
        // };
        throw error;
    }
};