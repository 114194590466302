import React, { useEffect, useState } from 'react';
import { addReview } from '../../components/reviews/ReviewAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, FormHelperText, TextField, Typography } from '@mui/material';
import RatingInput from '../../components/resources/RatingInput';
import { ErrorModal } from '../../components/resources/Modals';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';

const AddReview = () => {
  
  const authenticatedUser = useSelector((state) => state.userAuth);
  const { station_id } = useParams();
  Number(station_id);

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [price, setPrice] = useState('');
  const [email, setEmail] = useState(authenticatedUser?.user?.email);
  const [emailExist, setEmailExist] = useState(false);
  const [title, setTitle] = useState('');
  const [formErrors, setFormErrors] = useState({
    rating: '',
    email: '',
    price: '',
    comment: '',
    title: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  // Validate email format using a regular expression
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      rating: '',
      price: '',
      comment: '',
      title: '',
    };
  
    // Validate Rating
    if (rating === 0) {
      newErrors.rating = 'Rating is required';
      valid = false;
    }

    // Validate Price
    if (price.trim() === '') {
      newErrors.price = 'Price is required';
      valid = false;
    }
  
    // Validate Comment
    if (comment.trim() === '') {
      newErrors.comment = 'Comment is required';
      valid = false;
    }
  
    // Validate Title
    if (title.trim() === '') {
      newErrors.title = 'Title is required';
      valid = false;
    }
  
    // Only validate email if it's visible (user is not logged in)
    if (!localStorage.getItem('token')) {
      // Validate email format
      if (!emailPattern.test(email)) {
        newErrors.email = 'Incorrect email format';
        valid = false;
      }
    }
  
    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        setIsSubmitting(true)
        // Here, you can submit the form since it passed validation
        const data = await addReview({ station_id, rating, title, comment, price, email });
        if(data?.message === 'Review created successfully'){
          swal({
            title: "Review Added!",
            text: "Thanks For Telling Your Experience",
            icon: "success",
            buttons: {
              link: {
                text: "Proceed",
                value: "link",
                className: "custom-link-button"
              },
            },
            closeOnClickOutside: false, // Prevent the modal from closing when the outside is clicked
            closeOnEsc: false          // Prevent the modal from closing when the escape key is pressed
          }).then((value) => {
            if (value === "link") {
              // Handle the "Visit Link" button click action here if needed
              // This block will run when the user clicks the "Visit Link" button
              // You can also add custom logic here if required.
              window.open(`/station/${data?.station_id}`, '_self');

            //   window.history.replaceState({}, document.title, '/');
            }
          });
          setEmailExist(false);
        };
      } catch (error) {
        setIsSubmitting(false);
        console.error('Error adding review:', error);
        if (error.response && error.response.data.message === 'User with this email already exists') {
          setEmailExist(true);
        }
      }
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
    navigate('/login');
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEmailExist(false);
  };

  const errorContent = (
    <>
      <p>This email has been registered. It might be you.</p>
      <p>Would you like to Login?</p>
    </>
  );

  return (
    <div>
      {useEffect(() => {
        if (emailExist) {
          setIsModalOpen(true); // Show the modal when emailExist is true
        }
      }, [emailExist])}

      <ErrorModal errorContent={errorContent} isVisible={isModalOpen} onYes={handleOk} title={'Email Already Exist!'} onNo={handleCancel} />
      <Container maxWidth="xl" sx={{ px: 4, py: 8 }}>
        <Container maxWidth="lg">
          <form onSubmit={handleSubmit} className="mx-auto max-w-lg p-4 shadow-lg sm:p-6 lg:p-8">
            <Typography variant="h6" sx={{ mb: 2 }}>
              Rate
            </Typography>
            <Typography variant="p" align="center" sx={{ mb: 2 }}>
              Submitting your review takes just a minute and can provide valuable insights for fellow users seeking information about filling stations. <br />
            </Typography>

            <RatingInput
              allowHalf
              size="large"
              value={rating}
              onChange={(value) => setRating(value)}
            />
            <FormHelperText className='mb-1' style={{ color: '#d32f2f' }}>{formErrors.rating}</FormHelperText>

            {localStorage.getItem('token') ? '' : (
              <TextField
                fullWidth
                variant="outlined"
                id="outlined-error-helper-text"
                label="email"
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
                error={formErrors.email !== ''}
                helperText={formErrors.email}
              />
            )}

            <TextField
              fullWidth
              variant="outlined"
              type="number"
              label="Price"
              placeholder="Enter the price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              sx={{
                mb: 2,
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  appearance: 'none',
                  margin: 0,
                },
              }}
              error={formErrors.price !== ''}
              helperText={formErrors.price}
            />

            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="outlined-textarea"
              label="Tell us about Your Experience"
              placeholder="Share your experience, including what you liked, disliked, and any helpful insights for others."
              multiline
              rows={8}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              sx={{
                marginBottom: '1rem',
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
              }}
              error={formErrors.comment !== ''}
              helperText={formErrors.comment}
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Title"
              placeholder="Review Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{
                mb: 2,
              }}
              error={formErrors.title !== ''}
              helperText={formErrors.title}
            />

            <button
              type="submit"
              className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white hover:bg-indigo-700"
            >
              {isSubmitting? 'Submiting Review...' : 'Submit Review'}
            </button>
          </form>
        </Container>
      </Container>
    </div>
  );
};

export default AddReview;
