import React, { useEffect, useState } from "react";
import { editStation, getStations } from "../../../components/stations/StationsAPI";
import { Button } from "@mui/material";
import AdminLayout from "../../../components/Admin/layout/AdminLayout";
import PaginationTable from "../../../components/resources/PaginationTable";
import { ErrorModal } from "../../../components/resources/Modals";
import { toast } from 'react-toastify';
import { getUser } from "../../../components/user/UserApi";
import { Link } from "react-router-dom";

const AllStations = () => {
    const [stations, setStations] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [stationToApprove, setStationToApprove] = useState(null);
    const [user, setUser] = useState([])

    const fetchStations = async () => {
        try {
            const data = await getStations();
            setStations(data.station);
        } catch (error) {
            console.error('Error fetching Stations:', error);
        }
    };
    const fetchUser = async () => {
        try {
            const data = await getUser();
            setUser(data);
        } catch (error) {
            console.error('Error fetching Users:', error);
        }
    }

    const columns = [
        { id: 'name', label: 'Name', minWidth: 170 },
        { id: 'station_id', label: 'Station ID', minWidth: 100 },
        {
            id: 'state',
            label: 'State',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'lga',
            label: 'LGA',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'address',
            label: 'Address',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'average_price',
            label: 'Average Price',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'added_by',
            label: 'Added_by',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'rating',
            label: 'Rating',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'tableAction',
            label: 'Action',
            minWidth: 170,
            align: 'right',
        },
    ];

    useEffect(() => {
        fetchStations();
        fetchUser();
    }, []);

    // console.log(stations)
    const createData = (name, station_id, state, lga, address, average_price, added_by, rating, tableAction, ) => {
        const users = user?.find((user) => user.email === added_by);

        return { 
            name,
            station_id,
            state, lga, address,
            average_price,
            added_by: (
                <Link to={`/user/${users?.username}`}>
                    {users?.username}
                </Link>
            ),
            rating,
            tableAction 
        };
    }

    const rows = stations.map((station) => (
        createData(
            <Link style={{ fontWeight: 'bold', color: 'black' }} to={`/station/${station._id}`}>
                {station.name}
            </Link>,
            station.station_id, station.state, station.lga, station.address, station.average_price, station.added_by, station.rating, (
            <>
                <Button href={`/edit_stations/${station._id}`}>Edit</Button>
                <Button style={station.isApproved ? {display: 'none'}: { display: 'unset'}} onClick={(e) => {
                    e.preventDefault();
                    showApproveModal(station._id);
                }}>Unapprove</Button>
            </>
        ))
    ));

    const showApproveModal = (stationId) => {
        setStationToApprove(stationId);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const errorContent = (
        <>
          <p>Do you want to approve this station?</p>
        </>
    );
    const unApproveStation = async () => {
        try {
            const data = await editStation({ isApproved: false, station_id: stationToApprove });
            // Remove the approved station from the filtered list
            console.log(data)
            if(data.message === "Station updated"){
                toast.success('Station Unapproved')
            }
            // setStations(stations.filter(station => station._id !== stationToApprove));
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error Updating Station', error);
        }
    };

    return (
        <AdminLayout>
            <ErrorModal
                errorContent={errorContent}
                isVisible={isModalVisible}
                onYes={unApproveStation}
                title={'Confirm Approval'}
                onNo={handleCancel}
            />
            <div style={{ justifyContent: 'space-between', display: 'flex'}}>
                <h2>All Stations</h2>
                <Button variant="outlined" href="/stations/add" color="primary">Add Station</Button>
            </div>
            <PaginationTable columns={columns} rows={rows} />
        </AdminLayout>
    )
}

export default AllStations;
