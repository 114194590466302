import { useEffect, useState } from "react";
import { StationRating, StationReviewCard } from "../../components/reviews/StationReview";
import { Link, useParams } from "react-router-dom";
import { getSingleStation } from "../../components/stations/StationsAPI";
import { getstationReviews } from "../../components/reviews/ReviewAPI";
import InternalError from '../../components/resources/500Page';
import { Box, Button, Card, CardContent, CircularProgress, Grid, Paper, Skeleton, Typography, styled } from "@mui/material";

import RatingInput from "../../components/resources/RatingInput";
import CapitalizeFirstLetter from "../../components/resources/CapitalizeFirstLetter";
// import Mapc from "../../components/resources/Map";
import CustomPagination from "../../components/resources/CustomPagination";
import { editUser, getUser } from "../../components/user/UserApi";
import { useSelector } from 'react-redux';
import NotExist from "../../components/resources/NotExist";

const StationPage = () => {

    const [reviews, setReviews] = useState([]);
    const [station, setStation] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [user, setUser] = useState([]);
    const itemsPerPage = 10; // Number of items to display per page
    const authenticatedUser = useSelector((state) => state.userAuth);
    const [reviewLoading, setReviewLoading] = useState(true);
    const [notStation, setNotStation] = useState(false)

    const { station_id } = useParams();
    
    useEffect(() => {
        const fetchSingleStation = async () => {
            try {
                const data = await getSingleStation(station_id);
                setStation(data);
                const userData = await getUser();
                setUser(userData);
            } catch (error) {
                console.error('Error fetching station:', error);
                if (error.response && error.response.status === 404) {
                    setNotStation(true);
                };
            }
        };
        fetchSingleStation();
    }, [station_id]);

    useEffect(() => {
        if (station && station.station_id) {
            const fetchStationReview = async () => {
                try {
                    const data = await getstationReviews(station.station_id);
                    setReviews(data.reviews);
                    setReviewLoading(false);
                } catch (error) {
                    console.error('Error fetching station reviews:', error);
                }
            };
            fetchStationReview();
        }
    }, [station]);

    const StyledSection = styled(Paper)(({ theme }) => ({
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    }));
    
    const StyledRatingContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        // alignItems: 'center',
        gap: theme.spacing(1),
        flexDirection: 'column'
    }));
    
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    

    // Sort reviews by latest date
    const sortedReviews = reviews
    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = sortedReviews?.slice(startIndex, endIndex);
    
    if (notStation) {
        return <NotExist />; // Display a 404 page if user doesn't exist
    };

    return (
        <div style={{ background: '#eef3f7'}}>
            {
                station ? (
                    <div>
                        <Paper>
                        <StyledSection>
                            {station && (
                                <Grid container spacing={2} className="center-content">
                                    <Grid item xs={3} sm={2} md={2} lg={2} className="center-image content-center">
                                        <Paper className="mt-3 mb-2 station-logo">
                                            {/* <div> */}
                                            <img className="station-img" alt={station.name} src={station?.image} />
                                            {/* </div> */}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={10} md={5} className="center-content">
                                        <Typography variant="h4" className="mt-2"><strong>{station.name}</strong></Typography>
                                        <StyledRatingContainer className="mt-2">
                                            <RatingInput value={station.rating} size="large" readOnly />
                                            <em variant='body1' className="mb-2">{Number(station.rating).toFixed(2)}/ 5.00 - based on {reviews?.length} reviews</em>
                                        </StyledRatingContainer>
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={12} lg={4}>
                                    <div className="mt-2">
                                        <StationRating className="mt-3" reviews={reviews} star={station && (station.rating)}/>
                                    </div>
                                    </Grid>
                                </Grid>
                            )}
                        </StyledSection>
                        </Paper>
                        <div className="container" style={{paddingBottom: '30px'}}>
                            <div>
                                {station && 
                                    <Button href={`/reviews/add/${station.station_id}`} variant="contained" color="primary">Add Review</Button>
                                }
                            </div>
                            <Grid container spacing={2} style={{ flexWrap: 'wrap-reverse'}}>
                                <Grid item xs={12} md={7} sm={12}>
                                    {
                                        reviewLoading ? (
                                            Array.from({ length: 12 }).map((_, index) => (
                                                <Card key={index} className="mt-4" style={{ maxWidth: 'xl', borderRadius: '8px' }}>
                                                    <CardContent>
                                                        <Skeleton variant="text" width={120} height={30} />

                                                        <div className="flex items-center mb-3 space-x-4">
                                                        <Skeleton variant="circular" width={40} height={40} />
                                                        <div className="space-y-1 font-medium">
                                                            <Skeleton variant="text" width={150} height={20} />
                                                            <Skeleton variant="text" width={120} height={15} />
                                                        </div>
                                                        </div>

                                                        <div className="flex items-center mb-1">
                                                        {[1, 2, 3, 4].map((index) => (
                                                            <Skeleton
                                                            key={index}
                                                            variant="rectangular"
                                                            width={20}
                                                            height={20}
                                                            style={{ marginRight: '4px' }}
                                                            />
                                                        ))}
                                                        <Skeleton variant="text" width={180} height={20} />
                                                        </div>

                                                        <footer className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                                                        <Skeleton variant="text" width={120} height={15} />
                                                        </footer>

                                                        <Skeleton variant="text" width={'100%'} height={40} />

                                                        <aside>
                                                        <Skeleton variant="text" width={150} height={15} />

                                                        <div className="flex items-center mt-3 space-x-3 divide-x divide-gray-200 dark:divide-gray-600">
                                                            <Button
                                                            className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-xs px-2 py-1.5 dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                                                            disabled
                                                            >
                                                            Helpful
                                                            </Button>

                                                            <Link
                                                            href="#"
                                                            className="pl-4 text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                                                            >
                                                            Report abuse
                                                            </Link>
                                                        </div>
                                                        </aside>
                                                    </CardContent>
                                                </Card>
                                            ))
                                        ) : (
                                            reviews?.length === 0? (
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                                                    <svg width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="gray" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <line x1="12" y1="8" x2="12" y2="16"></line>
                                                        <line x1="12" y1="12" x2="12" y2="12"></line>
                                                    </svg>
                                                    <h3 style={{ fontSize: '16px', color: 'gray', marginTop: '10px' }}>No posts yet</h3>
                                                </div>
                                            ) : 
                                            reviews?.length > 0 ? (
                                                currentData?.map((review) => (
                                                    <StationReviewCard reviews={review} users={user && user} userId={authenticatedUser?.user?._id} key={review._id} />
                                                ))
                                            ) : (
                                                ''
                                            )
                                        )
                                        
                                    }
                                    {
                                        currentData.length > 0 ? (
                                            <CustomPagination
                                                totalItems={reviews.length}
                                                itemsPerPage={itemsPerPage}
                                                currentPage={currentPage}
                                                onPageChange={handlePageChange}
                                            />
                                        ) : ('')
                                    }
                                </Grid>
                                <Grid item xs={12} md={5} sm={12}>
                                <Card className="mt-4 sticky-list-card" style={{ maxWidth: 'xl', borderRadius: '8px' }}>
                                    <CardContent>
                                        <ul>
                                            <li className="mb-3">Average Price: <span style={{float: "right", fontWeight: "bold", fontSize: "25px"}}>{Number(station?.average_price).toFixed(2)}</span></li>
                                            <li>Address: <span style={{float: "right", fontWeight: "bold"}}>{station.address}</span></li>
                                            <li>State: <span style={{float: "right", fontWeight: "bold"}}>{station.state}</span></li>
                                            <li>Lga: <span style={{float: "right", fontWeight: "bold"}}>{station.lga}</span></li>
                                        </ul>
                                    </CardContent>
                                </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={80} />
                    </div>
                )
            }
        </div>
    )
};

export default StationPage;