import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUserAction } from '../../Slice/UserSlice';
import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import { Search } from '@mui/icons-material';
import { useState } from "react";

const Navbar1 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authenticatedUser = useSelector((state) => state.userAuth);
  const websiteSettings = useSelector((state) => state.website);
  
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    const searchParams = new URLSearchParams();
    searchParams.set('search', searchQuery);
    navigate(`/stations?${searchParams.toString()}`);
  };
  const handleLogout = () => {
    // Dispatch the logout action when the user clicks the logout button
    dispatch(logoutUserAction());

    // Reload the page
    window.location.reload();
  };

  const handleKeyPress = (e) => {
    // Check if the Enter key is pressed (key code 13)
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const navbarStyle = {
    marginTop: '105px', // Adjust this value to match your navbar's height
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
      <div className='navjj' style={navbarStyle}>
        <>
          <Navbar collapseOnSelect expand="lg" className="bg-white shadow fixed-top" style={{ zIndex: 1000 }}>
            <Container className='container mx-auto px-4 py-4'>
              <Navbar.Brand href="/">
                <img 
                  src={websiteSettings.logo} alt={websiteSettings.name}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Link className="text-gray-800 text-sm font-semibold hover:text-purple-600 mr-4" to="/">Home</Link>
                  <Link className="text-gray-800 text-sm font-semibold hover:text-purple-600 mr-4" to="/stations">Stations</Link>
                </Nav>
                <Nav className='me-auto'>
                  <div class="form">
                    <input onKeyPress={handleKeyPress} type="text" class="form-control form-input" placeholder="Search for Stations..." onChange={(e) => setSearchQuery(e.target.value)} />
                    <span class="left-pan"><i><Search style={{ cursor: 'pointer' }} className="mt-2" onClick={() => handleSearch(searchQuery)} /></i></span>
                  </div>
                </Nav>
                <Nav>
                {
                  authenticatedUser.user? (
                    <div style={{paddingTop: '10px'}}>
                      <Avatar
                        alt="User Avatar"
                        src={authenticatedUser?.user.profilePicture}
                        onClick={handleMenuOpen}
                      />
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem><Link to="/profile/update" >Settings</Link></MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </Menu>
                    </div>
                  ) : (
                    <div style={{paddingTop: '10px'}}>
                      <Link to="/login" class="text-gray-800 text-sm font-semibold hover:text-purple-600 mr-4">Sign in</Link>
                      <Link to="#" class="text-gray-800 text-sm font-semibold border px-4 py-2 rounded-lg hover:text-purple-600 hover:border-purple-600">Sign up</Link>
                    </div>
                  )
                }
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </>
      </div>
  );
};

export default Navbar1;