// FilterSection.js
import React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Space, Segmented } from "antd";

const FilterSection = ({
  selectedRating,
  handleOptionChange,
  selectedState,
  stateData,
  handleStateChange,
  selectedLGA,
  currentLGA,
  handleLGAChange,
  averagePrice,
  handlePriceChange

}) => {
  return (
    <div>
       <Box>
          <Box mb={2}>
              <Space direction="vertical">
                  <Segmented
                      size="large" 
                      options={['All', '3.0', '4.0', '4.5', '5']}
                      onChange={handleOptionChange}
                      value={selectedRating}
                  />
              </Space>
          </Box>
          <Box mb={2}>
              <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedState}
                      label="Select State"
                      onChange={handleStateChange}
                  >
                      <MenuItem value='all' selected>All</MenuItem>
                      {
                          stateData.map((item) => (
                              <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
                          ))
                      }
                  </Select>
              </FormControl>
          </Box>
          <Box>
          <FormControl fullWidth>
              <InputLabel id="lga-select-label">Select LGA</InputLabel>
              {selectedState === '' || selectedState === "Select State" || selectedState === "all" ? (
                  <Select
                      labelId="lga-select-label"
                      id="demo-simple-select"
                      value='Select State First'
                      label="Select LGA"
                      disabled
                  >
                      <MenuItem value='Select State First'>Select State First</MenuItem>
                  </Select>
              ) : (
                  <Select
                      labelId="lga-select-label"
                      id="demo-simple-select"
                      value={selectedLGA}
                      label="Select LGA"
                      onChange={handleLGAChange}
                  >
                      <MenuItem value="all" >All</MenuItem>
                      {currentLGA.lgas.map((item, i) => (
                          <MenuItem key={i} value={item}>{item}</MenuItem>
                      ))}
                  </Select>
              )}
          </FormControl>
          </Box>
          <TextField
                className="mt-2"
                fullWidth
                variant="outlined"
                type="number"
                label="Price"
                placeholder="Enter Max Price"
                value={averagePrice}
                onChange={handlePriceChange}
                sx={{
                mb: 2,
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    appearance: 'none',
                    margin: 0,
                },
                }}
            />
      </Box>
    </div>
  );
};

export default FilterSection;
