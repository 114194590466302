import axios from "axios";

// Set the token in the Axios headers
export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

axios.interceptors.request.use(config => {
  const token = localStorage.getItem("token") 
    ? (localStorage.getItem("token"))
    : null;
  if (token) {
    config.headers['Authorization'] = `${token}`;
  }
  return config;
});

// Log the user out
export const logout = () => {
  // Clear the token from both frontend and backend
  setAuthToken(null);
  localStorage.removeItem("token");
};
