import axios from 'axios';
import NotExist from '../resources/NotExist';
import InternalError from '../resources/500Page';

const API_URL = 'https://station-find-bke.onrender.com/api/station';
// const API_URL = 'http://localhost:5000/api/station';
export const getStations = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching Stations:', error);
    if(error.response.status === 500){
      window.open(`/500`, '_self');
    };
    throw error;
  }
};

export const getSingleStation = async (station_id) => {
  try {
    const response = await axios.get(`${API_URL}/${station_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Stations:', error);
    if(error.response.status === 500){
      window.open(`/500`, '_self');
    };
    throw error;
  }
};

export const addStation = async (newStation) => {
  try {
    const response = await axios.post(`${API_URL}`, newStation);
    return response.data;
  } catch (error) {
    console.error('Error adding Station:', error);
    if(error.response.status === 500){
      window.open(`/500`, '_self');
    };
    throw error;
  }
};

export const editStation = async (stationId, updatedStation) => {
  try {
    const response = await axios.patch(`${API_URL}/${stationId}`, updatedStation);
    return response.data;
  } catch (error) {
    console.error('Error editing Station:', error);
    if(error.response.status === 500){
      window.open(`/500`, '_self');
    };
  }
}
