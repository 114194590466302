import { Alert, Container, FormHelperText, TextField, Typography } from "@mui/material";
import { FormModal } from "../../../components/resources/Modals";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { registerUserAction } from '../../../Slice/UserSlice';
import { toast } from 'react-toastify';

const AddUser = ({ isVisible, onNo }) => {
    
    
    const storeData = useSelector((store) => store?.users);
    const { loading, registrationError, isAuthenticated } = storeData;
    
    const dispatch = useDispatch();

    const [user, setUser] = useState({
        firstname: "",
        lastname: "",
        email: "",
        username: "",
        password: "",
    });
    const [formErrors, setFormErrors] = useState({
        firstname: "",
        lastname: "",
        email: "",
        username: "",
        password: "",
    });

    // Validate email format using a regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            firstname: "",
            lastname: "",
            email: "",
            username: "",
            password: "",
        };
      
        if(user.firstname.trim() === ''){
            newErrors.firstname = 'firstname is Required';
            valid = false;
        }

        if(user.lastname.trim() === ''){
            newErrors.lastname = 'lastname is Required';
            valid = false;
        }

        if (!emailPattern.test(user.email)) {
            newErrors.email = 'Incorrect email format';
            valid = false;
        }
    
        // Validate Price
        if (user.username.trim() === '') {
          newErrors.username = 'Username is required';
          valid = false;
        }else if(user.username.trim().length < 3){
            newErrors.username = 'Username is too Short';
            valid = false;
        }
      
        // Validate Comment
        if (user.password.trim() === '') {
          newErrors.password = 'password is required';
          valid = false;
        }
      
        setFormErrors(newErrors);
        return valid;
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(validateForm()){
            try {
                const data = await dispatch(registerUserAction(user));
                console.log(data)
                if(data.payload.message === 'User registered') {
                    toast.success('User added Successfully', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                };
            } catch (error) {
                console.error('Registration Error', error)
            }
        };
    };
    const formContent = (
        <Container maxWidth="lg">

            <div>
            {registrationError ? (
                <Alert variant="filled" severity="error">
                    {registrationError}
                </Alert>
            ) : ''
            }
            </div>

            <div className="form-group mb-3">
                <TextField
                    fullWidth
                    variant="outlined"
                    label="First Name"
                    placeholder="First Name"
                    value={user.firstname}
                    onChange={(e) => setUser({ ...user, firstname: e.target.value })}
                />
                <FormHelperText className='mb-1' style={{ color: '#d32f2f' }}>{formErrors.firstname}</FormHelperText>
            </div>

            <div className="form-group mb-3">
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Last Name"
                    placeholder="LastName"
                    value={user.lastname}
                    onChange={(e) => setUser({ ...user, lastname: e.target.value })}
                />
                <FormHelperText className='mb-1' style={{ color: '#d32f2f' }}>{formErrors.lastname}</FormHelperText>
            </div>

            <div className="form-group mb-3">
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Username"
                    placeholder="Username"
                    value={user.username}
                    onChange={(e) => setUser({ ...user, username: e.target.value })}
                />
                <FormHelperText className='mb-1' style={{ color: '#d32f2f' }}>{formErrors.username}</FormHelperText>
            </div>

            <div className="form-group mb-3">
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Email"
                    placeholder="  Email"
                    value={user.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
                <FormHelperText className='mb-1' style={{ color: '#d32f2f' }}>{formErrors.email}</FormHelperText>
            </div>

            <div className="form-group mb-3">
                <TextField
                    fullWidth
                    type="password"
                    variant="outlined"
                    label="Password"
                    placeholder="Password"
                    value={user.password}
                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                />
                <FormHelperText className='mb-1' style={{ color: '#d32f2f' }}>{formErrors.password}</FormHelperText>
            </div>

            <button
                onClick={handleSubmit}
                type="submit"
                className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white hover:bg-indigo-700"
            >
                { loading ? "Registering..."  : "Add User"}
            </button>
        </Container>
    );

    return(
        <div>
            <FormModal
                title={'Add New User'}
                isVisible={isVisible}
                form={formContent}
                onNo={onNo}
            />
        </div>
    )
}

export default AddUser;