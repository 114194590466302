import { useEffect, useState } from "react";
import { getUser, editUser } from "../../../components/user/UserApi";
import AdminLayout from "../../../components/Admin/layout/AdminLayout";
import { Button } from "@mui/material";
import AddUser from "./AddUser";
import PaginationTable from "../../../components/resources/PaginationTable";
import { Link } from "react-router-dom";
import { ErrorModal } from "../../../components/resources/Modals";
import { toast } from 'react-toastify';

const AllUsers = () => {
    const [user, setUser] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userToSuspend, setuserToSuspend] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalVisible(false);
    };

    useEffect(() => {
        fetchUser()
    }, [])

    const fetchUser = async () => {
        try {
            const data = await getUser();
            setUser(data);
        } catch (error) {
            console.error('Error fetching Users:', error);
        }
    }

    const columns = [
        { id: "firstname", label: "Firstname", minWidth: 170 },
        { id: "lastname", label: "Lastname", minWidth: 170 },
        { id: "username", label: "Username", minWidth: 170 },
        { id: "email", label: "Email", minWidth: 170 },
        { id: "role", label: "Role", minWidth: 170 },
        { id: "isEmailVerified", label: "Email Verified", minWidth: 170 },
        { id: "createdAt", label: "Created At", minWidth: 170 },
        { id: "updatedAt", label: "Updated At", minWidth: 170 },
        { id: "actions", label: "Actions", minWidth: 100 },
      ];

    const createData = (firstname, lastname, username, email, role, isEmailVerified, createdAt, updatedAt, actions) => {
        const profileLink = `/user/${username}`;
        return { 
            firstname, lastname, 
            username: (
                <Link to={profileLink}>{username}</Link>
            ) , 
            email, role, 
            isEmailVerified, createdAt, 
            updatedAt, actions 
        };
    };
    
    const rows = user.map((users) => (
        createData(
          users.firstname,
          users.lastname,
          users.username,
          users.email,
          users.role,
          users.isEmailVerified ? "Yes" : "No",
          new Date(users.createdAt).toLocaleDateString(),
          new Date(users.updatedAt).toLocaleDateString(),
          (
            <Button
                variant="outlined"
                onClick={() => showApproveModal(users._id)}
                color={users.suspended ? "success" : "error"} // Change color based on suspension status
            >
                {users.suspended ? "Unsuspend Account" : "Suspend Account"} {/* Change label based on suspension status */}
            </Button>
          )
        )
    ));
    
    const showApproveModal = (userId) => {
        setuserToSuspend(userId);
        setIsModalVisible(true);
    };

    const errorContent = (
        <>
          <p>Do you want to suspend this User?</p>
        </>
    );

    const suspendUser = async () => {
        setIsModalVisible(false);
        try {
            // Determine whether to suspend or unsuspend based on the current suspended status
            const suspended = user.find((u) => u._id === userToSuspend)?.suspended;
    
            const data = await editUser(userToSuspend, { suspended: !suspended });
    
            if (data.message === "User updated") {
                suspended? toast.success(`User Unsuspended`) : toast.error(`User Suspended`);
                
                // Update the suspension status in the user state
                setUser((prevUsers) => {
                    return prevUsers.map((user) => {
                        if (user._id === userToSuspend) {
                            return { ...user, suspended: !suspended };
                        }
                        return user;
                    });
                });
            }
        } catch (error) {
            console.error('Error Updating User', error);
        }
    };    

    return (
        <AdminLayout>
            <ErrorModal
                errorContent={errorContent}
                isVisible={isModalVisible}
                onYes={suspendUser}
                title={'Confirm Approval'}
                onNo={handleCancel}
            />
            <div className="d-flex justify-content-between align-items-center">
            <h2>All Users</h2>
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                }}
            >
                Add User
            </Button>
        </div>
            <AddUser isVisible={isModalOpen} onNo={handleCancel} />
            <PaginationTable rows={rows} columns={columns} />
        </AdminLayout>
    )
}

export default AllUsers;
