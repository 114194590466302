import React, { useState } from 'react';
import { Container, TextField, Button, Typography } from '@mui/material';
import { submitReport } from '../../components/reviews/ReportedReviewsApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';

const ReportReview = () => {
    
    const authenticatedUser = useSelector((state) => state.userAuth);
    const { review_Id } = useParams();

    const [report, setReport] = useState({
        reviewId: review_Id,
        reason: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReport({
            ...report,
            [name]: value,
        });
    };
    // console.log(report);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            // Pass report data and review_Id as properties of an object
            const data = await submitReport(report);
            if(data?.message === 'Review Reported Successfully'){
              swal({
                title: "Report Submitted!",
                text: "Your report has been submitted",
                icon: "success",
                buttons: {
                  link: {
                    text: "Proceed",
                    value: "link",
                    className: "custom-link-button"
                  },
                },
                closeOnClickOutside: false, // Prevent the modal from closing when the outside is clicked
                closeOnEsc: false          // Prevent the modal from closing when the escape key is pressed
              }).then((value) => {
                if (value === "link") {
                  // Handle the "Visit Link" button click action here if needed
                  // This block will run when the user clicks the "Visit Link" button
                  // You can also add custom logic here if required.
                  window.open(`/`, '_self');

                  window.history.replaceState({}, document.title, '/');
                }
              });
            };
        } catch (error) {
            console.error('Error submitting report:', error);
            alert('An error occurred while submitting the report. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };
    
  return (
    <Container maxWidth="md" style={{marginTop: '120px'}}>
      <Typography variant="h4" gutterBottom>
        Submit a Report
      </Typography>

      <form onSubmit={handleSubmit}>

      <Typography variant="p" gutterBottom>
        Submiting as {authenticatedUser?.user?.email}
      </Typography>
        {/* <TextField
          fullWidth
          label="Title"
          name="title"
          value={report.title}
        //   onChange={handleChange}
          required
          margin="normal"
          variant="outlined"
        /> */}

        <TextField
          fullWidth
          label="Description"
          name="reason"
          value={report.reason}
          onChange={handleChange}
          required
          margin="normal"
          variant="outlined"
          multiline
          rows={4}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          size="large"
          sx={{ marginTop: 2 }}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Report'}
        </Button>

      </form>
    </Container>
  );
};

export default ReportReview;
