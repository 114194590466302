import { Avatar, Container, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { editStation, getSingleStation } from "../../components/stations/StationsAPI";
import { getStates } from "../../components/states/StateApi";
import { useEffect, useState } from "react";
import MapComponent from "../../components/resources/Map";
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import swal from 'sweetalert';

const EditStation = () => {
    const authenticatedUser = useSelector((state) => state.userAuth);
    const [state, setState] = useState([]);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [station, setStation] = useState();
    const [formErrors, setFormErrors] = useState({
        name: '',
        address: '',
        lga: '',
        stationImg: '',
    });

    const [selectedState, setSelectedState] = useState('all');
    const [selectedLGA, setSelectedLGA] = useState('all');
    const [stationImg, setStationImg] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { station_id } = useParams();

    useEffect(() => {
        fetchStates();
    }, []);

    const fetchStates = async () => {
        try {
            const data = await getStates();
            setState(data);
        } catch (error) {
            console.error('Error fetching States:', error);
        }
    }

    useEffect(() => {
        const fetchSingleStation = async () => {
            try {
                const data = await getSingleStation(station_id);
                setStation(data);
                setName(data.name);
                setAddress(data.address);
                setSelectedState(data.state);
                setSelectedLGA(data.lga);
                setSelectedImage(data.image)
            } catch (error) {
                console.error('Error fetching station:', error);
            }
        };
        fetchSingleStation();
    }, [station_id]);

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            name: '',
            address: '',
            lga: '',
            stationImg: '',
        };

        // Validate station name
        if (name.trim() === '') {
            newErrors.name = 'Station name is required';
            valid = false;
        }

        // Validate station address
        if (address.trim() === '') {
            newErrors.address = 'Station address is required';
            valid = false;
        }

        // Validate LGA
        if (selectedLGA === '' || selectedLGA === 'Select State First' || selectedLGA === 'all') {
            newErrors.lga = 'Please select a valid LGA';
            valid = false;
        }

        // Validate image upload
        // if (!stationImg) {
        //     newErrors.stationImg = 'Please select a valid image file';
        //     valid = false;
        // }

        setFormErrors(newErrors);
        return valid;
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.type.includes('image')) {
                // File type is an image
                setStationImg(file);
                setSelectedImage(URL.createObjectURL(file));
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    stationImg: '',
                }));
            } else {
                setStationImg(null);
                setSelectedImage(null);
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    stationImg: 'Please select a valid image file',
                }));
            }
        } else {
            setStationImg(null);
            setSelectedImage(null);
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                stationImg: '',
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                setIsSubmitting(true)
                const formData = new FormData();
                formData.append('name', name);
                formData.append('address', address);
                formData.append('state', selectedState);
                formData.append('lga', selectedLGA);
                formData.append('station_id', station_id);
                formData.append('stationImg', stationImg);
                const data = await editStation(station_id, formData);

                if (data?.message === 'Station updated') {
                    swal({
                        title: "Station Edited!",
                        text: "Station has been Edited",
                        icon: "success",
                        buttons: {
                            link: {
                                text: "Proceed",
                                value: "link",
                                className: "custom-link-button"
                            },
                        },
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then((value) => {
                        if (value === "link") {
                            authenticatedUser?.user?.isAdmin ?
                                window.open('/all/station', '_self') :
                                window.open(`/station/${station_id}`, '_self');
                        }
                    });
                };
            } catch (error) {
                setIsSubmitting(false)
                console.error('Error editing station:', error);
            }
        };
    };

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setSelectedLGA('all');
    };

    const handleLGAChange = (event) => {
        setSelectedLGA(event.target.value);
    };

    const currentLGA = state.find((state) => state.name === selectedState);

    return (
        <div>
            <Container maxWidth="xl" sx={{ px: 4, py: 8 }}>
                <Container maxWidth="lg">
                    <form onSubmit={handleSubmit} encType="multipart/form-data" className="mx-auto max-w-lg p-4 shadow-lg sm:p-6 lg:p-8">
                        <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                            Edit Station
                        </Typography>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="demo-simple-select-label">Select State</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select State"
                                value={selectedState}
                                onChange={handleStateChange}
                                sx={{ borderColor: 'gray.200' }}
                                disabled
                            >
                                <MenuItem value="all">All</MenuItem>
                                {state.map((state) => (
                                    <MenuItem key={state.name} value={state.name}>{state.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="lga-select-label">Select LGA</InputLabel>
                            {selectedState === '' || selectedState === "Select State" || selectedState === "all" ? (
                                <Select
                                    labelId="lga-select-label"
                                    id="demo-simple-select"
                                    value='Select State First'
                                    label="Select LGA"
                                    disabled
                                >
                                    <MenuItem value='Select State First'>Select State First</MenuItem>
                                </Select>
                            ) : (
                                <Select
                                    labelId="lga-select-label"
                                    id="demo-simple-select"
                                    value={selectedLGA}
                                    label="Select LGA"
                                    onChange={handleLGAChange}
                                >
                                    <MenuItem value="all" >All</MenuItem>
                                    {currentLGA?.lgas.map((item, i) => (
                                        <MenuItem key={i} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            )}
                            <FormHelperText style={{ color: '#d32f2f' }}>{formErrors.lga}</FormHelperText>
                        </FormControl>

                        <div className="mb-3" >
                            <FormControl fullWidth className={'mb-2'}>
                                <input
                                    type="file"
                                    name="stationImg"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                            </FormControl>

                            <div>Station Logo</div>

                            <Avatar 
                                className="mb-4"
                                style={{ margin: '0 auto', width: '200px', height: '200px', }}
                                alt="Station logo"
                                src={selectedImage}
                                sx={{ width: 100, height: 100 }}
                            />

                            <FormHelperText style={{ color: '#d32f2f' }}>{formErrors.stationImg}</FormHelperText>
                        </div>

                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Station Name"
                            placeholder="Station Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ mb: 2 }}
                            error={formErrors.name !== ''}
                            helperText={formErrors.name}
                        />

                        <TextField
                            multiline
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Station Address"
                            placeholder="Enter Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            sx={{
                                marginBottom: '1rem',
                                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                            }}
                            error={formErrors.address !== ''}
                            helperText={formErrors.address}
                        />

                        <button
                            type="submit"
                            className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white hover-bg-indigo-700"
                        >
                            { isSubmitting? 'Updating Station...' : 'Update Station' }
                        </button>
                    </form>
                </Container>
            </Container>
        </div>
    )
}

export default EditStation;
