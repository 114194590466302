// auth.js
import jwtDecode from 'jwt-decode';
import { setUser } from '../Slice/AuthUser'; // Import the setUser action
import { useDispatch } from 'react-redux';
import { getAuthUser } from '../components/user/UserApi';

const useAuth = () => {
  const dispatch = useDispatch();

  const fetchUserDetails = (userToken) => {
    if (userToken) {
        // Decode the token to obtain the user ID
        const decodedToken = jwtDecode(userToken);
        
        // Simulate fetching user details using the user ID (replace with actual API request)
        const userId = decodedToken.id;

        // Replace the following with your actual API request to fetch user details
        const fetchData = async () => {
        try {
            const userData = await getAuthUser(userId);
            dispatch(setUser(userData));
        } catch (error) {
            console.error('Error fetching data:', error);
            if(error.response?.status === 401){
              localStorage.clear();
              window.open(`/login`, '_self');
            }
        }
        };
        fetchData()
    }
  };

  return { fetchUserDetails };
};

export default useAuth;
