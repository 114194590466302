import { useEffect, useState } from "react";
// import { Paper } from "@mui/material";
import { getReviews } from "../../../components/reviews/ReviewAPI";
import PaginationTable from "../../../components/resources/PaginationTable";
import AdminLayout from "../../../components/Admin/layout/AdminLayout";
import { getUser } from "../../../components/user/UserApi";
import { Link } from "react-router-dom";

const AllReviews = () => {
    
    const [review, setReviews] = useState([]);
    const [user, setUser] = useState([]);
    
    useEffect(() => {
      fetchReviews();
      fetchUser()
    }, []);
    
    const fetchReviews = async () => {
        try {
          const data = await getReviews();
          setReviews(data.reviews);
          // console.log(sortedReviews);
        } catch (error) {
          console.error('Error fetching reviews:', error);
        }
    };
    
    const fetchUser = async () => {
      try {
        const data = await getUser();
        setUser(data);
      } catch (error) {
        console.error('Error fetching Users:', error);
      }
    }
    const sortedReviews = review
        .flatMap(station => station.reviews)
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const updatedSortedReviews = sortedReviews.map(reviewitem => {
    const station = review.find(station => station.reviews.some(r => r._id === reviewitem._id));
        return {
            ...reviewitem,
            station_name: station.station_name,
            station_id: station.station_id
        };
    });

    const columns = [
        { id: 'name', label: 'Name', minWidth: 170 },
        {
          id: 'station_Name',
          label: 'Station Name',
          minWidth: 170,
          align: 'right',
        },
        {
          id: 'review',
          label: 'Review',
          minWidth: 200,
          align: 'right',
        },
        {
          id: 'rating',
          label: 'Rating',
          minWidth: 170,
          align: 'right',
          format: (value) => value.toFixed(2),
        },
        {
            id: 'price',
            label: 'Price',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
          id: 'likes',
          label: 'Likes',
          minWidth: 170,
          align: 'right',
          format: (value) => value,
        },
        {
            id: 'disLikes',
            label: 'Dislikes',
            minWidth: 170,
            align: 'right',
            format: (value) => value,
        },
        {
          id: 'reports',
          label: 'Reports',
          minWidth: 170,
          align: 'right',
          format: (value) => value.toFixed(2),
        },
        {
          id: 'created_at',
          label: 'Created At',
          minWidth: 170,
          align: 'right',
        },
    ];
    

    const createData = (name, station_Name, review, rating, price, likes, dislikes, reports, created_at) => {
      const users = user?.find((user) => user._id === name);
      return {
        name: (
          <Link style={{ fontWeight: 'bold', color: 'black' }} to={user ? `/user/${users?.username}` : ''}>{users?.username || 'Anonymous'}</Link>
        ), 
        station_Name, review, rating, price, likes, dislikes, reports, created_at };
    }

    const rows = updatedSortedReviews.map((reviews) => (
        createData(reviews.user_id, reviews.station_name, reviews.comment, reviews.rating, reviews.price, reviews.likes.length, reviews.dislikes.length, reviews.size, new Date(reviews.created_at).toLocaleDateString() )
    ));
    
    return (
        <AdminLayout>
            <div style={{ justifyContent: 'space-between', display: 'flex'}}>
                <h2>All Reviews</h2>
            </div>
            <PaginationTable rows={rows} columns={columns} />
        </AdminLayout>
    )
}

export default AllReviews;