import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/Admin/layout/AdminLayout';
import { getReportedReviews, resolveReport } from '../../../components/reviews/ReportedReviewsApi'; // Import your API function
import PaginationTable from '../../../components/resources/PaginationTable'; // Import your PaginationTable component
import { Button } from '@mui/material';
import { ErrorModal } from '../../../components/resources/Modals';
import { getReviews } from '../../../components/reviews/ReviewAPI';
import { getUser } from '../../../components/user/UserApi';
import { Link } from 'react-router-dom';

const ReportedReviews = () => {
  const [reportedReviews, setReportedReviews] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reportToApprove, setReportToApprove] = useState(null);
  const [review, setReviews] = useState([]);
  const [user, setUser] = useState([]);

  useEffect(() => {
    // Fetch reported reviews when the component mounts
    const fetchReportedReviews = async () => {
      try {
        const response = await getReportedReviews(); // Replace with your actual API function
        setReportedReviews(response);
      } catch (error) {
        console.error('Error fetching reported reviews:', error);
      }
    };
    const fetchReviews = async () => {
      try {
        const data = await getReviews();
        setReviews(data.reviews);
        // console.log(sortedReviews);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    const fetchUser = async () => {
      try {
          const data = await getUser();
          setUser(data);
      } catch (error) {
          console.error('Error fetching Users:', error);
      }
    }

    fetchUser();
    fetchReviews();
    fetchReportedReviews();
  }, []);
  
  const resolveReportedReview = async () => {
    try {
      console.log(reportToApprove);
      // Call your API function to resolve the reported review
      const response = await resolveReport(reportToApprove); // Replace with your actual API function
      
      setIsModalVisible(false);
  
      // Check if the response contains the resolved reported review object
      if (response && response._id) {
        // Handle success, you can update your UI or state here
        console.log('Reported review resolved successfully:', response);
  
        // You can also update your local state to indicate that the review is resolved if needed
        const updatedReportedReviews = reportedReviews.map((report) =>
          report._id === response._id ? { ...report, resolved: true } : report
        );
        setReportedReviews(updatedReportedReviews);
      } else {
        // Handle unexpected response format or any other error conditions
        console.error('Unexpected response when resolving reported review:', response);
      }
    } catch (error) {
      console.error('Error resolving review:', error);
      // Handle the error, you can display an error message or perform other actions
    }
  };

  const updatedReportedReview = [];

  // Iterate through the reported reviews
  reportedReviews.forEach((reportedReview) => {
    // Find the corresponding review using reviewId
    const correspondingReview = review.find(
      (review) => review.reviews.some((r) => r._id === reportedReview.reviewId)
    );
      // console.log(correspondingReview)
    if (correspondingReview) {
      // Extract the required information from the inner array
      const { comment, user_id } = correspondingReview.reviews.find(
        (r) => r._id === reportedReview.reviewId
      );

      // Create a new object with merged data using the spread operator
      const mergedReview = {
        ...reportedReview,
        comment,
        station_name: correspondingReview.station_name,
        user_id,
      };

      // Push the merged review into the array
      updatedReportedReview.push(mergedReview);
    }
  });

  // Now, updatedReportedReview contains the reported reviews with comment, station name, and user ID

  const showApproveModal = (id) => {
    setReportToApprove(id);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    { id: 'reviewId', label: 'Review ID', minWidth: 100 },
    { id: 'reporter', label: 'Reporter', minWidth: 170 },
    { id: 'reason', label: 'Reason', minWidth: 170 },
    { id: 'reviewComment', label: 'Review Comment', minWidth: 170 },
    { id: 'reviewedBy', label: 'Reviewed By', minWidth: 170 },
    { id: 'stationName', label: 'Station Name', minWidth: 170 },
    { id: 'resolved', label: 'Status', minWidth: 100 },
    { id: 'action', label: 'Action', minWidth: 170 },
  ];

  const createData = (id, reviewId, reporter, reason, reviewComment, reviewedBy, stationName, resolved, action ) => {
    const users = user?.find((user) => user._id === reviewedBy);

    return { 
      id, reviewId, reporter, 
      reason, reviewComment, 
      reviewedBy: (
        <Link to={`/user/${users?.username}`}>
          {users?.username}
        </Link>
      ), 
      stationName, resolved, action};
  }
  
  const rows = updatedReportedReview.map((report) => (
    createData(
      report._id, // Assuming _id is a unique identifier for reported reviews
      report.reviewId,
      report.reporter,
      report.reason,
      report.comment,
      report.user_id,
      report.station_name,
      report.resolved ? 'Resolved' : 'Pending', // Format the status
      (
        <>
            <Button variant='outlined' 
                color={ report.resolved? "primary" : "success"}
                disabled={report.resolved}
                onClick={(e) => {
                e.preventDefault();
                showApproveModal(report._id)
            }}>Resolve Issue</Button>
        </>
      ),
    )
  ));
  
  const info = (
    <>
      <p>Are U Sure to resolve this Issue?</p>
    </>
  );
  
  return (
    <AdminLayout>
      <ErrorModal
          errorContent={info}
          isVisible={isModalVisible}
          onYes={resolveReportedReview}
          title={'Resolve Issue'}
          onNo={handleCancel}
      />
      <div style={{ justifyContent: 'space-between', display: 'flex'}}>
        <h2>Reported Reviews</h2>
      </div>
      <PaginationTable columns={columns} rows={rows} />
    </AdminLayout>
  );
};

export default ReportedReviews;
