import { useDispatch } from 'react-redux';
import { logoutUserAction } from '../../Slice/UserSlice';

// Inside your component

const Logout = async () => {
    const dispatch = useDispatch();
  try {
    // Dispatch the logout action
    await dispatch(logoutUserAction(null));
    // await 

    // Perform additional logout logic (e.g., clear local storage, redirect)
    // ...
  } catch (error) {
    // Handle error if logout request fails
    console.error('Error logging out:', error);
  }
};

export default Logout;