// userAuthSlice.js
import { createSlice } from '@reduxjs/toolkit';

 
const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState: {
    token: localStorage.getItem('token') || null, // Get the token from local storage if available
    user: null, // User details
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.token = null;
      state.user = null;
    },
  },
});

export const { setToken, setUser, clearUser } = userAuthSlice.actions;
export default userAuthSlice.reducer;
