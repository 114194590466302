import axios from "axios";

const API_URL = 'https://station-find-bke.onrender.com/api/user';
// const API_URL = 'http://localhost:5000/api/user';
export const getUser = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error fetching Users:', error);
        if(error.response.status === 500){
            window.open(`/500`, '_self');
        };
        throw error;
    }
};

export const getSingleUser = async (username) => {
    try {
        const response = await axios.get(`${API_URL}/${username}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching Single User:', error);
        if(error.response.status === 500){
            window.open(`/500`, '_self');
        };
        throw error;
    }
}

export const getAuthUser = async (userId) => {
    try {
        const response = await axios.get(`${API_URL}auth/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching Single User:', error);
        if(error.response.status === 500){
            window.open(`/500`, '_self');
        };
        throw error;
    }
}

export const editUser = async (userId, updatedUser) => {
  try {
    const response = await axios.patch(`${API_URL}/${userId}`, updatedUser);
    return response.data;
  } catch (error) {
    console.error('Error updating User:', error);
    if(error.response.status === 500){
        window.open(`/500`, '_self');
    };
    throw error;
  }
};
