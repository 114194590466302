import React from 'react';
import { Modal, Button } from 'antd';

export const ErrorModal = ({ title, isVisible, onYes, onNo, errorContent }) => {
  return (
    <Modal
      title={title}
      open={isVisible}
      footer={[
        <Button key="no" type="default" onClick={onNo}>
          No
        </Button>,
        <Button key="yes" style={{backgroundColor: 'blue', color: 'white'}} onClick={onYes}>
          Yes
        </Button>,
      ]}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ fontSize: '24px', color: 'red', marginRight: '10px' }}>&#9888;</span>
        <div>
          {errorContent}
        </div>
      </div>
    </Modal>
  );
};

// modal for Forms

export const FormModal = ({ title, isVisible, onNo, form }) => {
  return (
    <Modal
      centered
      title={title}
      open={isVisible}
      footer={null}
      onCancel={onNo}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {form}
      </div>
    </Modal>
  )
};

export const SuccessfulModal = ({ content, isVisible, onYes, onNo }) => {
  return (
    <div className="text-center">
      {/* <button className="trigger-btn" onClick={openModal}>
        Click to Open Confirm Modal
      </button> */}

      <Modal
        title="Awesome!"
        open={isVisible}
        onOk={onYes}
        onCancel={onNo}
        footer={[
          <Button key="ok" type="primary" onClick={onNo}>
            OK
          </Button>,
        ]}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="icon-box">
              <i className="material-icons">&#xE876;</i>
            </div>
          </div>
          <div className="modal-body">
            <p className="text-center">
              {content}
            </p>
          </div>
        </div>
      </Modal>
    </div>
  )
};