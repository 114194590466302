import { CardContent, CardMedia, Paper, styled } from "@mui/material";
import { Typography } from "antd";
import RatingInput from "../resources/RatingInput";
import CapitalizeFirstLetter from "../resources/CapitalizeFirstLetter";
import { Link } from "react-router-dom";

const StyledCard = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // padding: theme.spacing(1),
  flexGrow: 1,
}));

const StationList = ({ item }) => {
  
  return (
    <div>
      <StyledCard elevation={3} className="listingCard" sx={{ padding: 0 }}>
        <CardContent>
          <div className="Listing" style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
            <div className="Image" style={{ width: '200px', height: '200px', borderRadius: '12px', overflow: 'hidden', border: '0.50px #E5E7EB solid', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <CardMedia
                className="stationImg"
                sx={{ height: 200, flex: '1 1 0' }}
                image={item.image}
                title="station img"
              />
            </div>
            <div className="Content" style={{ flex: '1', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: '16px' }}>
              <div className="Header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: '24px' }}>
                <div className="TitleSubtitle" style={{ flex: '1', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '8px' }}>
                  <Typography className="Title" style={{ color: '#374151', fontSize: '32px', fontFamily: 'Inter', fontWeight: '500', lineHeight: '32px'}}><Link style={{ color: '#374151' }} to={`/station/${item._id}`}>{CapitalizeFirstLetter(item.name)}</Link></Typography>
                  <Typography className="Subtitle" style={{ color: '#6B7280', fontSize: '14px', fontFamily: 'Inter', fontWeight: '400', lineHeight: '20px' }}>{item.lga}, {item.state}, show in map</Typography>
                </div>
                <div className="Heart" style={{ width: '32px', height: '32px', position: 'relative' }}>
                  <div className="Vector" style={{ width: '19.33px', height: '16.67px', left: '6.33px', top: '7.67px', position: 'absolute', border: '0.75px #374151 solid' }}></div>
                </div>
              </div>
              <div className="Divider" style={{ width: '40px', height: '0px', border: '0.50px #E5E7EB solid', marginTop: '16px', marginBottom: '14px' }}></div>
              <div className="Details" style={{ height: '60px', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <Typography className="6GuestsEntireHome5Beds3Bath" style={{color: '#6B7280', fontSize: '14px', fontFamily: 'Inter', fontWeight: '400', lineHeight: '18px'}}>{item.address}</Typography>
              </div>
              <div className="Divider" style={{ width: '40px', height: '0px', border: '0.50px #E5E7EB solid', marginTop: '16px', marginBottom: '14px' }}></div>
              <div className="Footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
                <div className="Review" style={{ height: '20px', justifyContent: 'flex-start', alignItems: 'center', gap: '4px', display: 'flex' }}>
                  <div className="0" style={{ color: '#374151', fontSize: '14px', fontFamily: 'Inter', fontWeight: '500', lineHeight: '20px', wordWrap: 'break-word' }}>{item.rating.toFixed(2)}</div>
                  <div className="Star" style={{ height: '20px'}}>
                    <div className="Vector" 
                    style={{ height: '12.08px', marginTop: '-5%'}}
                    ><RatingInput readOnly size='small' value={item.rating} /></div>
                  </div>
                  {/* <div className="318Reviews" style={{ color: '#374151', fontSize: '14px', fontFamily: 'Inter', fontWeight: '400', lineHeight: '20px', wordWrap: 'break-word' }}>{(item. 'Reviews')}</div> */}
                </div>
                <div className="Review" style={{ justifyContent: 'flex-end', alignItems: 'center', gap: '8px', display: 'flex' }}>
                  <div className="350" style={{ color: '#374151', fontSize: '18px', fontFamily: 'Inter', fontWeight: '500', lineHeight: '28px', wordWrap: 'break-word' }}>₦ {Number(item.average_price).toFixed(2)}</div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </StyledCard>
    </div>
  );
};

export default StationList;
