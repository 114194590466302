import React, { useEffect, useState } from "react";
import { Reviews, ThumbUpAltRounded } from "@mui/icons-material";
import { CircularProgress, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from "react-router-dom";
import { getSingleUser } from "../../components/user/UserApi";
import { getReviews, getUserReview } from "../../components/reviews/ReviewAPI";
import { ReviewCard, ReviewCardSkeleton } from "../../components/reviews/StationReview";
import { getStations } from "../../components/stations/StationsAPI";
import NotExist from "../../components/resources/NotExist";
import CustomPagination from "../../components/resources/CustomPagination";

const UserAccount = () => {
  const { username } = useParams();
  const [user, setUser] = useState(null);
  const [review, setReview] = useState();
  const [allReviews, setAllReviews] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState(0);
  const [stations, setStations ] = useState([]);
  const [notUser, setNotUser] = useState(false)
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await getSingleUser(username);
        setUser(userData);
        if(user && user._id){
          const data = await getUserReview(user._id);
          setReview(data.userReviews);
        };
        const allReviewsData = await getReviews();
        setAllReviews(allReviewsData.reviews);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.data.message === 'User not found') {
          setNotUser(true);
        }
      }
    };

    const fetchStations = async () => {
      try {
        const data = await getStations();
        setStations(data.station);
      } catch (error) {
        console.error('Error fetching Stations:', error);
      }
    };

    fetchStations();
    fetchData();
  }, [username, user]);

  const sortedReviews = review?.flatMap(station => station.reviews)
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const sortedAll = allReviews?.flatMap(station => station.reviews)
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  // Function to update reviews with station_id and station_name
  const updateReviewsWithStationInfo = (reviews, allReviews, stations) => {
    return reviews?.map((reviewitem) => {
      const station = allReviews?.find((station) =>
        station.reviews.some((r) => r._id === reviewitem._id)
      );
      const matchingStation = stations?.find((s) => s.station_id === Number(station?.station_id));

      return {
        ...reviewitem,
        station_id: matchingStation?._id,
        station_name: station?.station_name,
      };
    });
  };
  
  const updatedSortedReviews = updateReviewsWithStationInfo(sortedReviews, allReviews, stations);
  const updatedSortedAll = updateReviewsWithStationInfo(sortedAll, allReviews, stations);

  const likedReviews = updatedSortedAll?.filter(review => review.likes.includes(user?._id));

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentDataOwn = updatedSortedReviews?.slice(startIndex, endIndex);
  const currentDataLiked = likedReviews?.slice(startIndex, endIndex);

  // Function to calculate total likes
  const calculateTotalLikes = () => {
    let totalLikes = 0;
    sortedAll?.forEach(review => {
      if (review.likes.includes(user?._id)) {
        totalLikes += 1;
      }
    });
    return totalLikes;
  };

  // Calculate the total likes
  const totalLikes = calculateTotalLikes();

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const avatarStyle = {
    width: '100%',
    maxWidth: '100px',
    height: 'auto',
    borderRadius: '50%',
  };

  const circleStyle = {
    marginBottom: '8px',
    width: '80px',
    height: '80px',
    lineHeight: '80px',
    textAlign: 'center',
    borderRadius: '50%',
    backgroundColor: '#3578fa',
    color: '#fff',
    whiteSpace: 'nowrap',
    fontSize: '2rem',
    fontWeight: 500,
  };

  if (notUser) {
    return <NotExist info="Sorry this user does not exist" />; // Display a 404 page if user doesn't exist
  }

  return (
    <div style={{ background: '#eef3f7' }}>
    {
      user? (
        <>
        <Paper style={{ padding: '16px', marginBottom: '16px' }}>
            <Grid container spacing={2}>
                {/* User avatar and information */}
                <Grid item xs={12} md={6} className="row d-flex">
                    <figure className="col-3">
                    <img src="https://th.bing.com/th/id/OIP.XhVVRF2VzD9RPoxQQqpv4gAAAA?w=167&h=180&c=7&r=0&o=5&pid=1.7" alt="" style={avatarStyle} />
                    </figure>
                    <div style={{ marginTop: '30px' }} className="col-9">
                    <Typography variant="h4">{user ? user.firstname + ' ' + user.lastname : ''}</Typography>
                    <Typography variant="subtitle1">@{user ? user.username : ''}</Typography>
                    </div>
                </Grid>
                <Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={12} md={6}>
                    <ul className="d-flex" style={{ justifyContent: 'space-between' }}>
                    <li className="mr-5" style={{ display: 'flex', flexDirection: 'column' }}>
                        <strong style={circleStyle}>{review?.length}</strong>
                        <span>
                        <i className="icon_star"><Reviews /></i> Reviews
                        </span>
                    </li>
                    <li style={{ display: 'flex', flexDirection: 'column' }}>
                        <strong style={circleStyle}>{totalLikes}</strong>
                        <span>
                        <i className="icon_like_alt"><ThumbUpAltRounded /></i> Likes
                        </span>
                    </li>
                    </ul>
                </Grid>
            </Grid>
        </Paper>
        <div className="container">
          {
            user?.suspended ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                  <svg width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="gray" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="16"></line>
                      <line x1="12" y1="12" x2="12" y2="12"></line>
                  </svg>
                  <h3 style={{ fontSize: '16px', color: 'gray', marginTop: '10px' }}>This Account has been suspended.</h3>
              </div>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                <Tabs value={value} onChange={handleTabChange} centered>
                    <Tab label="User's Reviews" />
                    <Tab label="Liked Reviews" />
                </Tabs>
                {value === 0 && (
                  <div>
                    {
                      review?.length === 0? (<h2>This User Has not Posted any Review</h2>) :
                      review?.length > 0 ? (
                          currentDataOwn.map(reviews => (
                            <ReviewCard key={reviews._id} reviews={reviews} users={user && [user]} userId={user && user._id} />                        
                          ))
                      ): (<ReviewCardSkeleton xs={12} sm={12} md={8}/>)
                    }
                    <CustomPagination
                      totalItems={review?.length}
                      itemsPerPage={itemsPerPage}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                  </div>
                )}
                {value === 1 && (
                  <div>
                    {
                      allReviews ? (
                        currentDataLiked.map(likedReview => (
                          <ReviewCard key={likedReview._id} reviews={likedReview} users={user && [user]} userId={user && user._id} />
                        ))
                      ): (<ReviewCardSkeleton xs={12} sm={12} md={8}/>)
                    }
                  </div>
                )}
                </Grid>
              </Grid>
            )
          }
          
        </div>
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress size={80} />
        </div>
      )
    }
    </div>
  );
};

export default UserAccount;
