import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getWebsiteSettings, updateWebsiteSettings } from '../components/Admin/WebsiteApi';

const initialState = {
  id: null,
  name: null,
  logo: null,
};

// Create an async thunk for getting website settings
export const getWebsiteSettingsAsync = createAsyncThunk(
  'website/getSettings',
  async () => {
    const response = await getWebsiteSettings(); // Replace with your actual API function
    return response;
  }
);

// Create an async thunk for updating website settings
export const updateWebsiteSettingsAsync = createAsyncThunk(
  'website/updateSettings',
  async ({ id, data }, { rejectWithValue }) => {
    try{
      const response = await updateWebsiteSettings(id, data); // Replace with your actual API function
      return response;
    }catch(error){
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error);
    };
  }
);

const websiteSlice = createSlice({
  name: 'website',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWebsiteSettingsAsync.fulfilled, (state, action) => {
        // Handle successful API response for getting website settings here
        state.id = action.payload?._id;
        state.name = action.payload?.name;
        state.logo = action.payload?.logo;
      })
      .addCase(updateWebsiteSettingsAsync.fulfilled, (state, action) => {
        // Handle successful API response for updating website settings here
        state.name = action.payload?.name;
        state.logo = action.payload?.logo;
      })
      .addCase(updateWebsiteSettingsAsync.rejected, (state, action) => {
        // Handle API request failure for updating website settings here if needed
        console.log(action.payload)
      });
  },
});

// export const { modifyWebsiteName, modifyWebsiteLogo } = websiteSlice.actions;
export default websiteSlice.reducer;
