import React from 'react';
import { Rate } from 'antd';
import '../../ratingCss.css';

const RatingInput = ({ value, onChange, readOnly, size }) => {
  const getColor = () => {
    if (value <= 1) return 'red';
    if (value <= 2) return 'orange';
    if (value <= 3) return 'yellow';
    if (value <= 4) return 'lime';
    return 'green';
  };
  
  const getSize = () => {
    if (size === 'small') return 'small-rating';
    if (size === 'large') return 'large-rating';
    if (size === 'smaller') return 'smaller-rating'
    return ''; // Default size
  };

  return (
    <Rate
      allowHalf
      value={value}
      onChange={readOnly ? undefined : onChange}
      className={`custom-rating ${getColor()} ${getSize()} ${readOnly ? 'read-only' : ''}`}
      disabled={readOnly}
    />
  );
};

export default RatingInput;
