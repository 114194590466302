import { Avatar, Container, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { addStation } from "../../components/stations/StationsAPI";
import { getStates } from "../../components/states/StateApi";
import { useEffect, useState } from "react";
import MapComponent from "../../components/resources/Map";
import swal from 'sweetalert';
import { useSelector } from "react-redux";

const AddStation = () => {
  
  const authenticatedUser = useSelector((state) => state.userAuth);

  const [state, setState] = useState([]);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [stationImg, setStationImg] = useState('');
  const [formErrors, setFormErrors] = useState({
    name: '',
    address: '',
    lga: '',
    stationImg: '',
  });

  const [selectedState, setSelectedState] = useState('all');
  const [selectedLGA, setSelectedLGA] = useState('all');

  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type.includes('image')) {
        // File type is an image
        setStationImg(file);
        setSelectedImage(URL.createObjectURL(file))
        setError(''); // Clear any previous error message
      } else {
        setSelectedImage(null);
        setError('Please select a valid image file.');
      }
    } else {
      setError('');
    }
  };

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const data = await getStates();
      setState(data);
    } catch (error) {
      console.error('Error fetching States:', error);
    }
  }

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: '',
      address: '',
      lga: '',
      stationImg: ''
    };

    // Validate station name
    if (name.trim() === '') {
      newErrors.name = 'Station name is required';
      valid = false;
    }

    // Validate station address
    if (address.trim() === '') {
      newErrors.address = 'Station address is required';
      valid = false;
    }

    // Validate LGA
    if (selectedLGA === '' || selectedLGA === 'Select State First' || selectedLGA === 'all') {
        newErrors.lga = 'Please select a valid LGA';
        valid = false;
    }

    // Validate image upload
    if (!stationImg) {
      newErrors.stationImg = 'Please select a valid image file';
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      try {
        setIsSubmitting(true)
        // Create a FormData object to store the form data
        const formData = new FormData();
  
        // Append form fields to the FormData
        formData.append('name', name);
        formData.append('address', address);
        formData.append('state', selectedState);
        formData.append('lga', selectedLGA);
        formData.append('added_by', authenticatedUser?.user?.email);
  
        // Append the image file to the FormData
        if (stationImg) {
          formData.append('stationImg', stationImg);
        }
  
        // Handle form submission with the FormData
        const data = await addStation(formData);
  
        if (data?.message === 'Station created') {
          swal({
            title: "Station Created!",
            text: "Station has been submitted for approval",
            icon: "success",
            buttons: {
              link: {
                text: "Proceed",
                value: "link",
                className: "custom-link-button"
              },
            },
            closeOnClickOutside: false, // Prevent the modal from closing when the outside is clicked
            closeOnEsc: false          // Prevent the modal from closing when the escape key is pressed
          }).then((value) => {
            if (value === "link") {
              // Handle the "Visit Link" button click action here if needed
              // This block will run when the user clicks the "Visit Link" button
              // You can also add custom logic here if required.
              window.open(`/stations`, '_self');
            }
          });
        };
      } catch (error) {
        setIsSubmitting(false)
        console.error('Error creating station:', error);
      }
    }
  };
  

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedLGA('all');
  };

  const handleLGAChange = (event) => {
    setSelectedLGA(event.target.value);
  }

  const currentLGA = state.find((state) => state.name === selectedState);

  const renderLGASelect = () => {
    if (selectedState === '' || selectedState === "Select State" || selectedState === "all") {
      return (
        <Select
          labelId="lga-select-label"
          id="demo-simple-select"
          value='Select State First'
          label="Select LGA"
          disabled
        >
          <MenuItem value='Select State First'>Select State First</MenuItem>
        </Select>
      );
    } else {
      return (
        <Select
          labelId="lga-select-label"
          id="demo-simple-select"
          value={selectedLGA}
          label="Select LGA"
          onChange={handleLGAChange}
        >
          <MenuItem value="all">All</MenuItem>
          {currentLGA.lgas.map((item, i) => (
            <MenuItem key={i} value={item}>{item}</MenuItem>
          ))}
        </Select>
      );
    }
  };

  return (
    <div>
      <Container maxWidth="xl" sx={{ px: 4, py: 8 }}>
          <form onSubmit={handleSubmit} encType="multipart/form-data" className="mx-auto max-w-lg p-4 shadow-lg sm:p-6 lg:p-8">
            <Typography variant="h6" align="center" sx={{ mb: 2 }}>
              Add Station
            </Typography>

            <div className="mb-3" >
              <FormControl fullWidth className={'mb-2'}>
              <input
                type="file"
                name="stationImg"
                accept="image/*"
                onChange={handleImageChange}
              />
              </FormControl>
                  
                <div>Station Logo</div>
                <Avatar 
                  className="mb-4"
                  style={{ margin: '0 auto', width: '200px', height: '200px', }}
                  alt="Station logo"
                  src={selectedImage ? selectedImage : stationImg}
                  sx={{ width: 100, height: 100 }}
                />
                
                <FormHelperText style={{ color: '#d32f2f' }}>{formErrors.stationImg}</FormHelperText>
            </div>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Select State</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select State"
                value={selectedState}
                onChange={handleStateChange}
                sx={{ borderColor: 'gray.200' }}
              >
                <MenuItem value="all">All</MenuItem>
                {state.map((state) => (
                  <MenuItem key={state.name} value={state.name}>{state.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="lga-select-label">Select LGA</InputLabel>
              {renderLGASelect()}
                <FormHelperText style={{ color: '#d32f2f' }}>{formErrors.lga}</FormHelperText>
            </FormControl>

            <TextField
                fullWidth
                variant="outlined"
                label="Station Name"
                placeholder="Station Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ mb: 2 }}
                error={formErrors.name !== ''}
                helperText={formErrors.name}
            />

            <TextField
                multiline
                fullWidth
                variant="outlined"
                size="small"
                label="Station Address"
                placeholder="Enter Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                sx={{
                    marginBottom: '1rem',
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                }}
                error={formErrors.address !== ''}
                helperText={formErrors.address}
            />

            <Typography variant="small" sx={{ mb: 2 }}>
              Station will be submitted for Approval.
            </Typography>

            <button
              type="submit"
              disabled={isSubmitting}
              className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white hover:bg-indigo-700"
            >
              {isSubmitting? 'Adding Station' : 'Add Station'}
              
            </button>
          </form>
      </Container>
    </div>
  )
}

export default AddStation;
