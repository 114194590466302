import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  Snackbar,
  FormHelperText,
  Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { editUser, getAuthUser } from '../../components/user/UserApi';
import jwtDecode from 'jwt-decode';
import { changePasswordAction } from '../../Slice/UserSlice'
import { toast } from 'react-toastify';

const UserProfile = () => {
    
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const authenticatedUser = useSelector((state) => state.userAuth);
  const authUser = authenticatedUser.user;
  const dispatch = useDispatch()
  const storeData = useSelector((store) => store?.users);
  const { loading, changePasswordErr, changePasswordSuccess } = storeData;

  const [formData, setFormData] = useState({
    firstname: authUser?.firstname,
    lastname: authUser?.lastname,
    phone: authUser?.phone || '',
    username: authUser?.username || '',
    email: authUser?.email || '',
    id: authUser?._id || '',
    profilePicture: authUser?.profilePicture,
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingP, setIsSubmittingP] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type.includes('image')) {
        // File type is an image
        setFormData({ ...formData, profilePicture: file });
        setSelectedImage(URL.createObjectURL(file))
        setError(''); // Clear any previous error message
      } else {
        setSelectedImage(null);
        setError('Please select a valid image file.');
      }
    } else {
      setError('');
    }
  };

  // console.log(formData);
  const fetchUserDetails = () => {
    if (authenticatedUser.token) {
        // Decode the token to obtain the user ID
        const decodedToken = jwtDecode(authenticatedUser.token);
        
        // Simulate fetching user details using the user ID (replace with actual API request)
        const userId = decodedToken.id;

        // Replace the following with your actual API request to fetch user details
        const fetchData = async () => {
        try {
            const userData = await getAuthUser(userId);
            setFormData(userData)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };
        fetchData()
    }
  };
  useEffect(() => {
    fetchUserDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [passwordError, setPasswordError] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  
  const validateForm = () => {
    let valid = true;
    const newErrors = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  
    // Validate Comment
    if (passwordData.currentPassword === '') {
      newErrors.currentPassword = 'Current password is required';
      valid = false;
    }

    if (passwordData.newPassword === '') {
      newErrors.newPassword = 'New password is required';
      valid = false;
    }

    if (passwordData.confirmPassword !== passwordData.newPassword) {
      newErrors.confirmPassword = 'password does not match';
      valid = false;
    }
  
    setPasswordError(newErrors);
    return valid;
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true)
      const datas = new FormData();

      // Append file to the form data
      datas.append('profilePicture', formData.profilePicture); // 'profilePicture' should match your server's field name

      // Append other form fields
      datas.append('firstname', formData.firstname);
      datas.append('lastname', formData.lastname);
      datas.append('phone', formData.phone);
      datas.append('username', formData.username);
      datas.append('email', formData.email);
      datas.append('id', authUser._id);

      // Add logic to save formData to the server or local storage
      const data = await editUser(authUser._id, datas);

      console.log(data)

      setOpenSnackbar(true);
    } catch (error) {
      setIsSubmitting(false)
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault()
    try {
      if(validateForm()){
        setIsSubmittingP(true)
        const data = dispatch(changePasswordAction({ id: authUser._id, user: passwordData }));
        
        if (changePasswordSuccess === "Password Changed") {
          toast.success("password changed")
        }
      }
    } catch (error) {
      setIsSubmittingP(false)
      console.error(error)
    }
  };

  return (
    <div style={{ background: '#eef3f7' }}>
      {
        authUser ? (
            <Container sx={{ py: 8 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={7}>
                        <Card>
                            <CardContent>
                            <Typography variant="h4" gutterBottom>
                                User Settings
                            </Typography>
                            <form onSubmit={handleSubmit} method="POST" encType="multipart/form-data">
                                <div>
                                  <FormControl fullWidth className={'mb-3'}>
                                    <input
                                      type="file"
                                      name="profilePicture"
                                      accept="image/*"
                                      onChange={handleImageChange}
                                    />
                                  </FormControl>

                                    {/* <img
                                      src={selectedImage ? selectedImage : formData.profilePicture}
                                      alt="Selected"
                                      style={{ maxWidth: '100px', maxHeight: '100px' }}
                                    /> */}
                                    <Avatar 
                                      className="mb-4"
                                      style={{ margin: '0 auto', width: '200px', height: '200px', }}
                                      alt="User Profile"
                                      src={selectedImage ? selectedImage : formData.profilePicture}
                                      sx={{ width: 100, height: 100 }}
                                    />
                                  {error && <p style={{ color: 'red' }}>{error}</p>}
                                </div>
                                <FormControl fullWidth className={'mb-3'}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    name="email"
                                    label="Email"
                                    placeholder="Email"
                                    value={authUser?.email}
                                />
                                </FormControl>
                                <FormControl fullWidth className={'mb-3'}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Firstname"
                                    label="Firstname"
                                    name="firstname"
                                    value={formData.firstname}
                                    onChange={handleChange}
                                />
                                </FormControl>
                                <FormControl fullWidth className={'mb-3'}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="lastname"
                                    label="Lastname"
                                    placeholder="Lastname"
                                    value={formData.lastname}
                                    onChange={handleChange}
                                />
                                </FormControl>
                                <FormControl fullWidth className={'mb-3'}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="username"
                                    label="Username"
                                    placeholder="Username"
                                    value={formData.username}
                                    onChange={handleChange}
                                />
                                </FormControl>
                                <FormControl fullWidth className={'mb-3'}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="phone"
                                    label="phone"
                                    placeholder="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                </FormControl>
                                {/* <FormControl variant="outlined" fullWidth className={'mb-3'}>
                                <InputLabel htmlFor="notificationPreference">Notification Preferences</InputLabel>
                                <Select
                                    name="notificationPreference"
                                    value={formData.notificationPreference}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="email">Email</MenuItem>
                                    <MenuItem value="sms">SMS</MenuItem>
                                    <MenuItem value="both">Both</MenuItem>
                                </Select>
                                </FormControl> */}
                                <Button variant="contained" disabled={isSubmitting} color="primary" type="submit">
                                  {isSubmitting? 'Loading...' : 'Save Settings'}
                                </Button>
                            </form>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                            Password Settings
                            </Typography>
                            <form onSubmit={handlePasswordSubmit}>
                            {changePasswordErr ? (
                                <Alert variant="filled" severity="error">
                                    {changePasswordErr}
                                </Alert>
                            ) : ''
                            }
                            <FormControl fullWidth className={'mb-3'}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                name="currentPassword"
                                label="Current Password"
                                type="password"
                                value={passwordData.currentPassword}
                                onChange={handlePasswordChange}
                                required
                                />
                                <FormHelperText style={{ color: '#d32f2f' }}>{passwordError.currentPassword}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth className={'mb-3'}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="New Password"
                                name="newPassword"
                                type="password"
                                value={passwordData.newPassword}
                                onChange={handlePasswordChange}
                                required
                                />
                                <FormHelperText style={{ color: '#d32f2f' }}>{passwordError.newPassword}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth className={'mb-3'}>
                                <TextField
                                variant="outlined"
                                fullWidth
                                label="Confirm New Password"
                                name="confirmPassword"
                                type="password"
                                value={passwordData.confirmPassword}
                                onChange={handlePasswordChange}
                                required
                                />
                                <FormHelperText style={{ color: '#d32f2f' }}>{passwordError.confirmPassword}</FormHelperText>
                            </FormControl>
                            <Button variant="contained" disabled={isSubmittingP} color="primary" type="submit">
                                {isSubmittingP? 'loading...' : 'Change Password'}
                            </Button>
                            </form>
                        </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message="Settings saved successfully!"
            />
            </Container>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress size={80} />
          </div>
        )
      }
    </div>
  );
};

export default UserProfile;
